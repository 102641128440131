import React from "react";
import { Link } from "react-router-dom";
import Logoimg from "../assets/statixpro logo.png";
import "./navbar.css"

function Navbar() {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark">
        <Link className="navbar-brand" to="/">
          <img src={Logoimg} alt="Statix.pro" width="150" className="logo-img" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item home_nav_items">
              <Link className="nav-link" to="/">Home</Link>
            </li>
            <li className="nav-item home_nav_items">
              <Link className="nav-link" to="/about-us">About Us</Link>
            </li>
            
            {/* Services Dropdown */}
            <li className="nav-item dropdown home_nav_items">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                id="servicesDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Services
              </Link>
              <ul className="dropdown-menu" aria-labelledby="servicesDropdown">
                <li>
                  <Link className="dropdown-item" to="/brand-strategy">
                    Consultancy & Strategy Development
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/branding-designs">
                    Branding Services & Designs
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/digital-marketing">
                    Digital Marketing & Lead Generation
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/technology-solutions">
                    Technology Solutions
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/data-analytics">
                    Data Analytics & Technical Optimization
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item home_nav_items">
              <Link className="nav-link" to="/portfolio">Portfolio</Link>
            </li>
            <li className="nav-item home_nav_items">
              <Link className="nav-link" to="/blog">Blog</Link>
            </li>
            <li className="nav-item home_nav_items">
              <Link className="nav-link" to="/contact-us">Contact Us</Link>
            </li>
            <li className="nav-item home_nav_items">
              <Link className="nav-link" to="/career">Career</Link>
            </li>
            <li className="nav-item home_nav_items">
              <a href="https://calendly.com/statixmarketing/30min" target="_blank" rel="noopener noreferrer" className="btn ml-3 meet-button">Schedule a Meet</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
