import React, { useEffect } from 'react'
import Navbar from '../../components/navbar/navbar'
import AddressBar from '../../components/address_bar/address_bar';
import "./digitalMarketing.css";
import Footer from '../../components/footer/footer';
import Chatsection from '../../components/chatsection/chatsection';
// import Footer from '../../components/footer/footer';
import Whatsappbutton from '../../components/whatsappbutton/whatsappbutton';
import Backtotopbtn from '../../components/backtotop/backtotop';
import SeoHelmet from '../../components/SEO/SeoHelmet';
import { servicesSchemas } from '../../components/SEO/SeoSchemas';
import ReactGA from 'react-ga4';

// Icons
import { FaSearch, FaBullhorn, FaUsers, FaChartLine } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { AiOutlineUsergroupAdd, AiFillRead } from "react-icons/ai";
import { BsPeopleFill } from "react-icons/bs";

// images
import DigitalMarketingimg from "../../components/assets/digital-marketing-img.png"

function DigitalMarketing() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    
    // Add schema markup to the page
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(servicesSchemas.digitalMarketing);
    document.head.appendChild(script);

    // Cleanup
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div>
       <SeoHelmet 
        title="Digital Marketing Services - StatixPro"
        description="Boost your online presence with StatixPro's comprehensive digital marketing solutions. From SEO to social media marketing, we deliver data-driven results that grow your business."
        keywords="digital marketing, SEO services, social media marketing, content marketing, PPC advertising, email marketing, digital strategy"
        canonicalUrl="https://www.statix.pro/digital-marketing"
        ogImage="https://www.statix.pro/digital-marketing-og.jpg"
        ogType="website"
      />
<AddressBar/>
 {/* Home page */}
 <section className="servicebs-hero">
        <div className="logo-and-nav">
          {/* Bootstrap Navbar */}
          <Navbar/>
        </div>

        {/* Hero Content */}
        <div className="servicebs-hero-content text-center">
        <h1 className='servicebs_topic'><span className="highlight">Digital Marketing & Lead Generation</span></h1>
          {/* <p>AI-powered <span className="highlight">Digital marketing & Branding</span> that gets results!</p> */}
          {/* <p>Discover who we are, what we stand for, and how we’re making a difference in the digital landscape.</p> */}
          <div className="hero-buttons mt-4">
            <a href="https://forms.gle/vGMNrdFWgVPy29GL8" target="_blank" rel="noopener noreferrer"><button className="btn view-button mr-3">Get a Free Consultancy</button></a>
            {/* <button className="btn btn-secondary contact-button">Contact us</button> */}
          </div>
        </div>
      </section>


      
      {/* Fuel your growth starts here */}
      <section style={{marginTop:"60px", marginBottom:"70px"}}>
      <div className="container section d-flex align-items-center">
      <div className="row align-items-center">
        {/* Image Section */}
        <div className="col-md-5 image-container text-center">
          <img
            src={DigitalMarketingimg} // Replace with your image URL
            alt="Brand Strategy"
            className="img-fluid"
          />
        </div>

        {/* Text Section */}
        <div className="col-md-7 order-md-1 text-container">
          <h1>
            <span className="highlight">Fuel Your Growth </span> with AI-Driven Digital Marketing
          </h1>
          <br/>
          <p>
            Statix.pro offers AI-driven branding strategies tailored to your needs,
            enabling you to stand out in the competitive market. Their expertise
            combines data-driven insights with advanced technology to create impactful,
            captivating brand strategies.
          </p>
          <br/>

          <button className="btn btn-primary">Looking to Drive More Leads?</button>
        </div>
      </div>
    </div>
      </section>
      {/* Fuel your growth ends here */}

      {/* Expertise solutions starts here */}
      <section className="expertise-section">
      <div className="container">
        <h2 className="text-center section-title">
          Our <span style={{ color: "#ff7b00" }}>Digital Marketing Expertise</span> Includes:
        </h2>
        <div className="row mt-4" style={{ paddingTop: "40px" }}>
          <div className="col-md-6 col-lg-4 mb-4 expertise-item">
            <div className="expertise-card text-center">
              <FaSearch className="expertise-icon" />
              <h4 className="item-title">SEO</h4>
              <p className="item-description">
                Come to the top with AI-enhanced SEO strategies that will
                increase your visibility and drive organic traffic to your site.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4 expertise-item">
            <div className="expertise-card text-center">
              <FaBullhorn className="expertise-icon" />
              <h4 className="item-title">Search Engine Marketing</h4>
              <p className="item-description">
                Unlock the potential of paid search campaigns to drive instant
                traffic and optimize your ROI.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4 expertise-item">
            <div className="expertise-card text-center">
              <FaUsers className="expertise-icon" />
              <h4 className="item-title">Social Media Management</h4>
              <p className="item-description">
                Stay relevant and engaged with expertly managed social media
                accounts that speak for your brand.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4 expertise-item">
            <div className="expertise-card text-center">
              <FaChartLine className="expertise-icon" />
              <h4 className="item-title">Social Media Marketing</h4>
              <p className="item-description">
                Leverage targeted ads on popular platforms to reach your
                audience at the place that matters.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4 expertise-item">
            <div className="expertise-card text-center">
              <AiFillRead className="expertise-icon" />
              <h4 className="item-title">Content Marketing</h4>
              <p className="item-description">
                Engage and inspire with great and relevant content designed to
                attract, educate, and convert.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4 expertise-item">
            <div className="expertise-card text-center">
              <AiOutlineUsergroupAdd className="expertise-icon" />
              <h4 className="item-title">Lead Generation</h4>
              <p className="item-description">
                Develop qualified lead pipelines through industry-leading AI
                capabilities that track and focus on high-potential prospects.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4 expertise-item">
            <div className="expertise-card text-center">
              <BsPeopleFill className="expertise-icon" />
              <h4 className="item-title">Influencer Marketing</h4>
              <p className="item-description">
                Partner with influencers to extend your reach and add
                credibility to your brand, turning followers into loyal
                customers.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4 expertise-item">
            <div className="expertise-card text-center">
              <FiMail className="expertise-icon" />
              <h4 className="item-title">Email Marketing</h4>
              <p className="item-description">
                Run efficient and personalized campaigns into the inboxes of
                your target audience, driving engagement and conversions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
      {/* Expertise solutions ends here */}


    {/* CTA starts here */}
    <section className="brand-section d-flex align-items-center justify-content-center">
      <div className="text-center">
        <h2 className="section-title text-light">Let's Drive Your Business Forward</h2>
        <p className="section-description">
        Ready to dominate in the digital space? Let Statix.pro help you unlock unparalleled marketing potential.
        </p>
        <button className="btn section-button">
        Let’s Connect
        </button>
        <p className="section-footer">
        Contact us today and experience how we can change the face of your online presence and create customers out of mere visitors!
        </p>
      </div>
    </section>
    {/* CTA ends here */}

        
        <Footer/>
    <Whatsappbutton/>
<Backtotopbtn/>
{/* chatsection */}
<Chatsection/>
    </div>
  )
}

export default DigitalMarketing