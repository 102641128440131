import React from 'react';
import './home.css';
import AddressBar from '../../components/address_bar/address_bar';
import RecentWorkSection from '../../components/recent_works/recent_works';
import FAQSection from '../../components/faq_section/faq_section';
import NumbersSection from '../../components/number_section/number_section';
import Navbar from '../../components/navbar/navbar';
import Chatsection from '../../components/chatsection/chatsection';
import Footer from '../../components/footer/footer';
import Whatsappbutton from '../../components/whatsappbutton/whatsappbutton';
import Backtotopbtn from '../../components/backtotop/backtotop';
import SeoHelmet from '../../components/SEO/SeoHelmet';
import ReactGA from 'react-ga4';
import { 
  organizationSchema, 
  websiteSchema, 
  professionalServiceSchema,
  faqSchema 
} from '../../components/SEO/SeoSchemas';

// images
import personImage from '../../components/assets/statixpro_employee.png';
import backgroundImage from '../../components/assets/whatsappbg.png';



function Home() {
  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    // All schemas for the home page
    const schemas = [
      organizationSchema,
      websiteSchema,
      professionalServiceSchema,
      faqSchema
    ];
    
    // Create and append all schema scripts
    const scripts = schemas.map(schema => {
      const script = document.createElement('script');
      script.type = 'application/ld+json';
      script.text = JSON.stringify(schema);
      return script;
    });
    
    scripts.forEach(script => document.head.appendChild(script));

    // Cleanup
    return () => {
      scripts.forEach(script => document.head.removeChild(script));
    };
  }, []);

  return (
    <div>
      <SeoHelmet 
        title="StatixPro - AI-Powered Digital Marketing & Branding Solutions"
        description="Transform your business with StatixPro's AI-powered digital marketing and branding solutions. Get strategic, data-driven results that drive real growth."
        keywords="digital marketing, AI marketing, branding solutions, SEO services, social media marketing, AI web development, content creation, digital strategy, marketing agency, StatixPro"
        canonicalUrl="https://www.statix.pro"
        // ogImage="https://www.statix.pro/og-image-home.jpg"
        ogType="website"
        twitterCard="summary_large_image"
        locale="en_US"
      />
      <AddressBar />

      {/* Home page */}
      <section className="hero">
        <div className="logo-and-nav">
          {/* Bootstrap Navbar */}
          <Navbar/>
        </div>

        {/* Hero Content */}
        <div className="hero-content text-center">
          <h1>Ready to Make Some Real Results?</h1>
          <p>AI-powered <span className="highlight">Digital marketing & Branding</span> that gets results!</p>
          <p>#Strategic, #Disruptive, #Data-Driven.</p>
          <div className="hero-buttons mt-4">
            <a href="https://heyzine.com/flip-book/6d0719c6ba.html" target="_blank" rel="noopener noreferrer"><button className="btn view-button mr-3">View Brochure</button></a>
            <a href="https://forms.gle/4Ki54AeKH5VqHwjz7" target="_blank" rel="noopener noreferrer"><button className="btn btn-secondary contact-button">Get Free Consultancy</button></a>
          </div>
        </div>
      </section>

    {/* numbering section starts here */}
    <NumbersSection/>
    {/* numbering section ends here */}

    {/* what we do section starts here */}
    <section className="services-section">
  <div className="container text-center">
    <h4 className="section-subtitle">What we do?</h4>
    <h2 className="section-title">Growing Together, Building Strength and <br/> Resilience Like a Coral Reef</h2>
    <div className="service-items">
      <span className="service-item">Branding Services</span>
      <span className="service-item">Creative Design</span>
      <span className="service-item">Branding Consulting</span>
      <span className="service-item">Package Designing</span>
      <span className="service-item">Data Analytics and Reporting</span>
      <span className="service-item">Lead Generation</span>
      <span className="service-item">Search Engine Optimization</span>
      <span className="service-item">Digital Marketing Services</span>
      <span className="service-item">Website & Mobile App Development</span>
      <span className="service-item">Content Creation and Copywriting</span>
      <span className="service-item">Graphic Design</span>
      <span className="service-item">Social Media Management</span>
      <span className="service-item">Technical Audits</span>
      <span className="service-item">Custom Softwares</span>
    </div>
  </div>
</section>

    {/* what we do section ends here */}

    {/* why us? starts here */}
    <section className="why-us-section">
  <div className="container">
    <h4 className="section-subtitle">Why us?</h4>
    <h2 className="section-title">Crafting Excellence Across Every Dimension.</h2>
    <div className="whyus-features row mt-4">
      <div className="col-md-3 col-sm-6 mb-4">
        <h5 className="feature-title">Boundless Innovation</h5>
        <p className="feature-description">
          Our approach is all about redefining limits. We bring fresh perspectives and bold creativity, staying ahead in a digital world that never stands still.
        </p>
      </div>
      <div className="col-md-3 col-sm-6 mb-4">
        <h5 className="feature-title">Smart Data, Smart Decisions:</h5>
        <p className="feature-description">
          Fueled by advanced AI and data, every strategy we create is precisely tailored for maximum impact, no guesswork.
        </p>
      </div>
      <div className="col-md-3 col-sm-6 mb-4">
        <h5 className="feature-title">Our Secret Advantage – AI on the Inside</h5>
        <p className="feature-description">
          At the core of our solutions is a proprietary AI tool, a custom innovation that powers unmatched precision and efficiency in every campaign.
        </p>
      </div>
      <div className="col-md-3 col-sm-6 mb-4">
        <h5 className="feature-title">Results that Resonate</h5>
        <p className="feature-description">
          In just half a year, we’ve partnered with educational institutions and small businesses to drive 5x more leads and expand brand reach exponentially.
        </p>
      </div>
    </div>
  </div>
</section>

    {/* why us? ends here */}

    {/* secret revealed here starts here */}
    <section className="strategy-section container">
      <h2 className="text-center">No Secrets, Just Strategy: Here's How We Make It Happen</h2>
      <div className="row justify-content-center align-items-start strategy-steps">
        
        {/* Step 1 */}
        <div className="col-md-3 step">
          <div className="circle">1</div>
          <div className="step-content">
            <h5>Analyse competitors</h5>
            <p>(Primary strategy, Challenge, USP, customer reviews)</p>
          </div>
          {/* <span className="arrow">→</span> */}
        </div>
        
        {/* Step 2 */}
        <div className="col-md-3 step">
          <div className="circle">2</div>
          <div className="step-content">
            <h5>Forming AI Based Content Strategy</h5>
            <div className="content-split">
              <div>
                <h6>Part A (AI Involved)</h6>
                <ul>
                  <li>Analysis of Data</li>
                  <li>Identifying Patterns</li>
                  <li>Running Simulations</li>
                </ul>
              </div>
              <div>
                <h6>Part B (Humans)</h6>
                <ul>
                  <li>Creativity</li>
                  <li>Understanding Emotions</li>
                  <li>Ethics</li>
                  <li>Diverse View Point</li>
                </ul>
              </div>
            </div>
          </div>
          {/* <span className="arrow">↓</span> */}
        </div>

        {/* Step 3 */}
        <div className="col-md-3 step">
          <div className="circle">3</div>
          <div className="step-content">
            <h5>AI automated marketing funnels</h5>
            <p>(Awareness - Consideration - Familiarity - Conversion - Building Relationship)</p>
          </div>
          {/* <span className="arrow">→</span> */}
        </div>

        {/* Step 4 */}
        <div className="col-md-3 step">
          <div className="circle">4</div>
          <div className="step-content">
            <h5>AI Optimised Campaigns</h5>
            <p>(Automated Campaign + Social Media Handling)</p>
          </div>
          {/* <span className="arrow">←</span> */}
          {/* Step 5 */}
        <div className="step" style={{marginTop:"30px"}}>
          <div className="circle">5</div>
          <div className="step-content">
            <h5>Hypertargeting Advertising</h5>
            <p>(Tailoring promotions)</p>
          </div>
        </div>
        </div>

        
      </div>
    </section>
    {/* secret revealed here ends here */}

    {/* recent works starts here */}
    <div>
    <RecentWorkSection/>
    </div>
    {/* recent works ends here */}

    {/* FAQ section starts here */}
    <FAQSection/>
    {/* FAQ section ends here */}

    {/* testimonial starts here */}
    <section className="testimonial-section py-5">
      <div className=" container">
        <h2 className="section-title text-center">We Provide Value of Our Clients</h2>
        
        <p className="text-center mb-5">
        Our clients' stories are the driving force behind everything we do. Whether it’s boosting engagement or achieving incredible brand growth, their experiences highlight the true impact of our AI-driven marketing and branding solutions. Here’s a glimpse into their journeys with us—we’re honored to be a part of their success!
        </p>
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="testimonial-card p-4">
              <div className="quote-icon text-center mb-3">&#8220;</div>
              <p className="text-center mb-4">
              Partnering with this company was a game-changer for us! Their AI-driven strategies not only identified our audience but also delivered campaigns that resonated deeply. We saw a good increase in engagement within weeks!
              </p>
              <h5 className="text-center">Sindhiya Manoj - Marketing Manager</h5>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="testimonial-card p-4">
              <div className="quote-icon text-center mb-3">&#8220;</div>
              <p className="text-center mb-4">
              The precision and insights from their AI tools are unmatched. Our branding has never been so aligned with our audience's needs. It’s like they read our customers' minds!
              </p>
              <h5 className="text-center">Danish Manohar - Brand Strategist</h5>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="testimonial-card p-4">
              <div className="quote-icon text-center mb-3">&#8220;</div>
              <p className="text-center mb-4">
              Their AI-powered approach turned our stagnant online presence into a thriving platform. Our ROI skyrocketed, and our brand now stands out in a crowded market!
              </p>
              <h5 className="text-center">Chandhrasekaran - CEO</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* testimonial ends here */}
    
    {/* contact whatsapp section starts here */}
    <section 
      className="contact-section d-flex justify-content-center align-items-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="row align-items-center container" style={{paddingTop:"30px"}}>
        {/* Image Column */}
        <div className="col-12 col-md-6 mb-3 mb-md-0 text-center">
          <img src={personImage} alt="Person" className="contact-image" />
        </div>

        {/* Text Column */}
        <div className="col-12 col-md-6 text-section text-white text-center text-md-start">
          <h2>Need More Clarification? Starting up a project?</h2>
          <button className="btn mt-3 lets-talk-btn" style={{marginBottom:"20px"}} onClick={() => window.open(`https://wa.me/+919361091908?text=Hi, I'm interested in Statixpro and your services. Willing to work with you.`)}>Let's talk</button>
          <p className="mt-2">
            This number is reserved for text chat only. If you need to schedule a meeting, kindly check the availability with our team.
          </p>
        </div>
      </div>
    </section>
    {/* contact whatsapp section ends here */}

      <Footer/>
      {/* footer section ends here */}

      {/* <WhatsAppButton/> */}
      <Whatsappbutton/>
      <Backtotopbtn/>
      {/* chatsection */}
      <Chatsection/>

    </div>
  );
}

export default Home;
