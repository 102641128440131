import React, { useEffect, useState } from 'react';
import './numbers_section.css';

const NumbersSection = () => {
  const [years, setYears] = useState(0);
  const [projects, setProjects] = useState(0);
  const [clients, setClients] = useState(0);

  useEffect(() => {
    const animateValue = (start, end, duration, setState) => {
      let startTime = null;

      const step = (timestamp) => {
        if (!startTime) startTime = timestamp;
        const progress = Math.min((timestamp - startTime) / duration, 1);
        setState(Math.floor(progress * (end - start) + start));

        if (progress < 1) {
          requestAnimationFrame(step);
        }
      };

      requestAnimationFrame(step);
    };

    animateValue(0, 3, 2000, setYears);       // Count to 3 in 2 seconds
    animateValue(0, 20, 2000, setProjects);   // Count to 20 in 2 seconds
    animateValue(0, 18, 2000, setClients);    // Count to 18 in 2 seconds
  }, []);

  return (
    <section className="numbers-section">
      <div className="container text-center">
        <div className="row">
          <div className="col-md-4 col-12">
            <div className="number-item">
              <span className="number">{years}</span>
              <p className="number-text">Years of Experience</p>
            </div>
          </div>
          <div className="col-md-4 col-12">
            <div className="number-item">
              <span className="number">{projects}</span>
              <p className="number-text">Projects with Experience</p>
            </div>
          </div>
          <div className="col-md-4 col-12">
            <div className="number-item">
              <span className="number">{clients}</span>
              <p className="number-text">Clients with Results</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NumbersSection;
