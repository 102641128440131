import React from 'react'
import logoImage from '../assets/statixpro logo.png';
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
        {/* footer section starts here */}
<footer className="footer-dark py-5">
      <div className="container">
        {/* Header Section */}
        <div className="row justify-content-between align-items-center mb-4">
          <div className="col-md-8 text-light">
            <h2>We Make Your Stories Better and Stronger!</h2>
          </div>
          <div className="col-md-4 d-flex justify-content-md-end mt-3 mt-md-0">
            <a href="https://forms.gle/oS71KjsgsyWKRmC1A" target="_blank" rel="noreferrer"><button className="btn btn-orange me-2">Get Started</button></a>
            <Link to="/contact-us"><button className="btn btn-light">Contact Us</button></Link>
          </div>
        </div>

        {/* Divider */}
        <hr className="divider" />

        {/* Footer Links Section */}
        <div className="row mt-4">
          {/* Column 1 */}
          <div className="col-md-5 mb-4 mb-md-0 text-light">
          <img src={logoImage} alt="Person" style={{width:"200px", paddingBottom:"20px"}} />
            <p style={{textAlign:"left"}}>
            We are a premier AI-powered digital marketing company in India, driving businesses forward with innovative strategies and data-driven insights. Our comprehensive services empower brands in the digital landscape through dynamic campaigns, optimized online presence, and AI-powered solutions.
            </p>
          </div>

          {/* Column 4 */}
          <div className="col-md-3 mb-4 mb-md-0">
            <h5 className="text-light">Resources</h5>
            <ul className="list-unstyled">
            {/* <li to="/privacyPolicies" > <Link className="nav-link" to="/pricing">Pricing Terms</Link>  </li>  */}
              <li> <Link className="nav-link" to="/pricing">Pricing Terms</Link> </li> 
              <li>FAQs</li>
              <li> <Link className="nav-link" to="/about-us">About us </Link></li>
              <li><Link className="nav-link" to="/portfolio">Portfolio</Link></li>
              <li><Link className="nav-link" to="/privacy-policy">Privacy Policy & Terms</Link></li>
            </ul>
          </div>

          {/* Column 5 */}
          <div className="col-md-4">
            <h5 className="text-light">Support</h5>
            <ul className="list-unstyled">
              <li > <Link className="nav-link" to="/contact-us">Contact </Link></li>
              <li>Affiliates</li>
              <li > <Link className="nav-link" to="/cancellation-terms">Cancellation Policy </Link></li>
              <li ><Link className="nav-link" target="_blank" rel="noopener noreferrer" to="https://maps.app.goo.gl/saDgQZo3oXab9HoR6"> Location</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
    </div>
  )
}

export default Footer