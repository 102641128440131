import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import Navbar from '../../components/navbar/navbar'
import AddressBar from '../../components/address_bar/address_bar';
import "./brandingDesigns.css";
import Footer from '../../components/footer/footer';
import Chatsection from '../../components/chatsection/chatsection';
// import Footer from '../../components/footer/footer';
import Whatsappbutton from '../../components/whatsappbutton/whatsappbutton';
import Backtotopbtn from '../../components/backtotop/backtotop';

import SeoHelmet from '../../components/SEO/SeoHelmet';
import { servicesSchemas } from '../../components/SEO/SeoSchemas';
import ReactGA from 'react-ga4';


import { FaPencilRuler, FaBullhorn, FaBoxOpen, FaEdit } from "react-icons/fa";
import BrandingDesignImg from "../../components/assets/branddesign-image.jpg";


function BrandingDesign() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    
    // Add schema markup to the page
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(servicesSchemas.brandingDesigns);
    document.head.appendChild(script);

    // Cleanup
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div>
        <SeoHelmet 
        title="Professional Branding Design Services - StatixPro"
        description="Transform your brand identity with StatixPro's professional branding design services. From logos to visual guidelines, we create memorable brand experiences."
        keywords="branding design, logo design, visual identity, brand guidelines, corporate branding, brand identity design, creative design services"
        canonicalUrl="https://www.statix.pro/branding-designs"
        ogImage="https://www.statix.pro/branding-designs-og.jpg"
        ogType="website"
      />
        <AddressBar/>
 {/* Home page */}
 <section className="servicebs-hero">
        <div className="logo-and-nav">
          {/* Bootstrap Navbar */}
          <Navbar/>
        </div>

        {/* Hero Content */}
        <div className="servicebs-hero-content text-center">
          <h1 className='servicebs_topic'><span className="highlight">Branding Services & Designs</span></h1>
          {/* <p>AI-powered <span className="highlight">Digital marketing & Branding</span> that gets results!</p> */}
          {/* <p>Discover who we are, what we stand for, and how we’re making a difference in the digital landscape.</p> */}
          <div className="hero-buttons mt-4">
            <a href="https://forms.gle/V39UtUNXCgUXzpGY9" target="_blank" rel="noopener noreferrer"><button className="btn view-button mr-3">Get a Free Consultancy</button></a>
            {/* <button className="btn btn-secondary contact-button">Contact us</button> */}
          </div>
        </div>
      </section>

      {/* Section 2 */}
      <div className="container my-5">
      <h2 className="text-center section-title mb-4" style={{paddingBottom:"50px", paddingTop:"30px"}}>Our Design Solutions Include</h2>
      <div className="row">
        {/* Card 1 */}
        <div className="col-lg-3 col-md-6 mb-4">
          <div className="solution-card text-center">
            <div className="icon-circle mx-auto mb-3">
              <FaPencilRuler />
            </div>
            <h5 className="card-title">Logo & Brand Identity</h5>
            <p className="card-description">
              Make your first impression unforgettable with a unique and visually stunning logo that embodies your brand’s values.
            </p>
          </div>
        </div>

        {/* Card 2 */}
        <div className="col-lg-3 col-md-6 mb-4">
          <div className="solution-card text-center">
            <div className="icon-circle mx-auto mb-3">
              <FaBullhorn />
            </div>
            <h5 className="card-title">Business Promotional Design</h5>
            <p className="card-description">
              Amplify your reach with promotional materials designed to captivate and convert, from brochures to banners and beyond.
            </p>
          </div>
        </div>

        {/* Card 3 */}
        <div className="col-lg-3 col-md-6 mb-4">
          <div className="solution-card text-center">
            <div className="icon-circle mx-auto mb-3">
              <FaBoxOpen />
            </div>
            <h5 className="card-title">Package & Label Design</h5>
            <p className="card-description">
              Create packaging that stands out on shelves and communicates the quality and personality of your brand.
            </p>
          </div>
        </div>

        {/* Card 4 */}
        <div className="col-lg-3 col-md-6 mb-4">
          <div className="solution-card text-center">
            <div className="icon-circle mx-auto mb-3">
              <FaEdit />
            </div>
            <h5 className="card-title">Content Creation & Copywriting</h5>
            <p className="card-description">
              Engage your audience with compelling content that tells your story and drives action.
            </p>
          </div>
        </div>

        <div>
        <button className="btn section-button" style={{marginTop:"30px"}} >
        <Link to="/portfolio" style={{textDecoration:"none", color:"white"}}>Looking for more? See Our Works</Link>
        </button>
        </div>
      </div>
    </div>


    {/* secction 3 starts here */}
    {/* Section 2 Starts here */}
    <div className="container my-5">
    <h2 className="text-center section-title mb-4" style={{ paddingTop:"40px"}}>We Dare to Be Different.Your Brand, Our Honesty. A New Era of Transparency. 
    </h2>
     <p style={{paddingBottom:"60px", fontSize:"20px"}} > Remember, In The world, creativity without impact is just noise—and we’re here to create a symphony.</p>
     <img
  src={BrandingDesignImg}
  alt="Digital Media Branding Strategy"
  className="responsive-image"
/>
     </div>
    {/* secction 3 ends here */}

    {/* section 4 starts here */}
    {/* CTA starts here */}
    <section className="brand-section d-flex align-items-center justify-content-center">
      <div className="text-center">
        <h2 className="section-title text-light">Let Your Brand Do All The Actions</h2>
        <p className="section-description">
        Whether you’re starting from scratch or looking to revamp your visuals, Statix.pro delivers creative solutions that define and elevate your brand.
        </p>
        <button className="btn section-button">
        Looking to Captivate Your Audience?
        </button>
        <p className="section-footer">
        Let’s collaborate and transform your vision into a stunning reality. Get in touch with us today!</p>
      </div>
    </section>
    {/* CTA ends here */}
    {/* section 4 ends here */}

<Footer/>
    <Whatsappbutton/>
<Backtotopbtn/>
{/* chatsection */}
<Chatsection/>
    </div>
  )
}

export default BrandingDesign