import React, { useState } from 'react';
import './chatsection.css';
import '@fortawesome/fontawesome-free/css/all.min.css';


function ChatSection() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedSubtopic, setSelectedSubtopic] = useState(null);

  const topics = [
    {
      title: 'Services',
      subtopics: [
        { question: 'What services does Statix.pro offer?', answer: 'At Statix.pro, we provide a wide range of services, including AI-powered digital marketing, branding, SEO, lead generation, website & mobile app development, content creation, social media management, and custom software solutions. Feel free to check our Services page for more details!' },
        { question: 'How can Statix.pro help my business grow?', answer: 'We focus on creating data-driven strategies that are tailored to your business. With our AI-powered tools and creative campaigns, we help drive more leads, boost brand visibility, and build strong customer relationships.' },
        { question: 'Do you offer custom software development?', answer: 'Yes, we provide custom software solutions designed to meet your unique business needs. Our team works with you to understand your requirements and create tailored applications for maximum efficiency.' }
      ]
    },
    {
      title: 'AI and Digital Marketing',
      subtopics: [
        { question: 'What is AI-based content strategy?', answer: 'AI-based content strategy uses advanced data analysis and simulations to create content that resonates with your target audience. Our AI tools analyze patterns and optimize content for maximum impact, while human creativity ensures the message is emotionally engaging.' },
        { question: 'How does AI optimize campaigns?', answer: 'AI helps automate campaign management, analyze performance, and continuously optimize for the best results. We use AI for automated marketing funnels, targeting the right audience at the right time, leading to more conversions and long-term relationships.' },
        { question: 'What is hypertargeting advertising?', answer: 'Hypertargeting advertising focuses on tailoring promotions to specific segments of your audience. This ensures that your ads are seen by the most relevant people, increasing the chances of conversion and improving ROI.' }
      ]
    }
    // Add more topics here if needed
  ];

  const toggleChat = () => {
    setIsOpen(!isOpen);
    setSelectedTopic(null); // Reset selected topic when chat is toggled
    setSelectedSubtopic(null); // Reset selected subtopic when chat is toggled
  };

  const handleTopicClick = (topicIndex) => {
    setSelectedTopic(topicIndex === selectedTopic ? null : topicIndex);
    setSelectedSubtopic(null); // Reset selected subtopic when a new topic is selected
  };

  const handleSubtopicClick = (subtopicIndex) => {
    setSelectedSubtopic(subtopicIndex === selectedSubtopic ? null : subtopicIndex);
  };

  return (
    <div className="chat-wrapper">
      <button
        className="chat-toggle-button"
        onClick={toggleChat}
        aria-label="Toggle Chat Assistant"
      >
        {isOpen ? '✖' : <i className="fas fa-comments"></i>}
      </button>

      {isOpen && (
        <div className="chat-assistant-container">
          <div className="chat-assistant-header">
            <span className="chat-assistant-title">Statix.pro</span> <br/>
            <p className="chat-assistant-description">Ask us anything, or share your feedback.</p>
          </div>

          <div className='scrollable-container'> 

          <div className="chat-assistant-options">
            {topics.map((topic, topicIndex) => (
              <button
                key={topicIndex}
                className="chat-assistant-option-btn"
                onClick={() => handleTopicClick(topicIndex)}
              >
                {topic.title}
              </button>
            ))}
          </div>

          {selectedTopic !== null && topics[selectedTopic] && (
            <div className="chat-assistant-subtopics">
              <button
                className="back-btn"
                onClick={() => setSelectedTopic(null)}
              >
                ⬅ Back to Topics
              </button>
              {topics[selectedTopic].subtopics.map((subtopic, subtopicIndex) => (
                <button
                  key={subtopicIndex}
                  className="chat-assistant-option-btn"
                  onClick={() => handleSubtopicClick(subtopicIndex)}
                >
                  {subtopic.question}
                </button>
              ))}
            </div>
          )}

          {selectedSubtopic !== null && topics[selectedTopic]?.subtopics[selectedSubtopic] && (
            <div className="chat-assistant-body">
              <div className="chat-assistant-message">
                <div className="chat-assistant-text">
                  <p><strong>Q:</strong> {topics[selectedTopic].subtopics[selectedSubtopic].question}</p>
                  <p><strong>A:</strong> {topics[selectedTopic].subtopics[selectedSubtopic].answer}</p>
                </div>
              </div>
            </div>
          )}

          </div>
        </div>
      )}
    </div>
  );
}

export default ChatSection;
