import React, { useEffect } from 'react';
import "./brandStratergy.css";
import AddressBar from '../../components/address_bar/address_bar';
import Navbar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';
import Chatsection from '../../components/chatsection/chatsection';
import Whatsappbutton from '../../components/whatsappbutton/whatsappbutton';
import Backtotopbtn from '../../components/backtotop/backtotop';
import SeoHelmet from '../../components/SEO/SeoHelmet';
import { servicesSchemas } from '../../components/SEO/SeoSchemas';
import ReactGA from 'react-ga4';
import Brandimg from "../../components/assets/branding-img.png";
import BrandLaunch from "../../components/assets/brand stratergy icons/Brand Launch Strategy.png"
import B2CBranding  from "../../components/assets/brand stratergy icons/B2C Branding Strategy.png"
import BrandPositioning from "../../components/assets/brand stratergy icons/Brand Positioning Strategy.png"
import DigitalMedia from "../../components/assets/brand stratergy icons/Digital Media Branding Strategy.png"
import IntegratedBrand from "../../components/assets/brand stratergy icons/Integrated Brand Strategy.png"
import PersonalBranding from "../../components/assets/brand stratergy icons/Personal Branding Strategy.png"
import BrandExtension from "../../components/assets/brand stratergy icons/Brand Extension Strategy.png"

function BrandStratergy() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    
    // Add schema markup to the page
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(servicesSchemas.brandStrategy);
    document.head.appendChild(script);

    // Cleanup
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div>
      <SeoHelmet 
        title="Brand Strategy Services - StatixPro"
        description="Transform your brand with StatixPro's strategic branding solutions. Our data-driven approach helps create memorable brand identities that resonate with your target audience."
        keywords="brand strategy, brand development, brand identity, brand positioning, brand guidelines, brand marketing, StatixPro branding"
        canonicalUrl="https://www.statix.pro/brand-strategy"
        // ogImage="https://www.statix.pro/brand-strategy-og.jpg"
        ogType="website"
      />
      <AddressBar/>
 {/* Home page */}
 <section className="servicebs-hero">
        <div className="logo-and-nav">
          {/* Bootstrap Navbar */}
          <Navbar/>
        </div>

        {/* Hero Content */}
        <div className="servicebs-hero-content text-center">
          <h1 className='servicebs_topic'><span className="highlight">Brand Consultancy & Strategy Development</span></h1>
          {/* <p>AI-powered <span className="highlight">Digital marketing & Branding</span> that gets results!</p> */}
          {/* <p>Discover who we are, what we stand for, and how we’re making a difference in the digital landscape.</p> */}
          <div className="hero-buttons mt-4">
            <a href="https://forms.gle/EGvX6vjszLzLw61m9" target="_blank" rel="noopener noreferrer"><button className="btn view-button mr-3">Get a Free Consultancy</button></a>
            {/* <button className="btn btn-secondary contact-button">Contact us</button> */}
          </div>
        </div>
      </section>

      <section>
      <div className="container section d-flex align-items-center">
      <div className="row align-items-center">
        {/* Image Section */}
        <div className="col-md-5 image-container text-center">
          <img
            src={Brandimg} 
            alt="Brand Strategy"
            className="img-fluid"
          />
        </div>

        {/* Text Section */}
        <div className="col-md-7 order-md-1 text-container">
          <h1>
            Redefining <span className="highlight">Brand Strategy</span> with AI: A Synergistic Approach
          </h1>
          <br/>
          <p>
            Statix.pro offers AI-driven branding strategies tailored to your needs,
            enabling you to stand out in the competitive market. Their expertise
            combines data-driven insights with advanced technology to create impactful,
            captivating brand strategies.
          </p>
          <br/>

          <button className="btn btn-primary">Connect us</button>
        </div>
      </div>
    </div>
      </section>

    {/* brand expert solutions */}

    <section className="expert-solutions container py-5">
      <h2 className="text-center">
        Expert Solutions, <span className="highlight">Made for You</span>
      </h2>
      <div className="row g-4">
        {/* Brand Launch Strategy */}
        <div className="col-lg-6 d-flex">
          <img
            src={BrandLaunch}
            alt="Brand Launch Strategy"
            className="solution-image me-3"
          />
          <div>
            <h5 className="solution-title">Brand Launch Strategy</h5>
            <p className="solution-description">
              Build the right buzz with a well-planned launch strategy that
              captures eyeballs and creates engagement right from day one.
            </p>
          </div>
        </div>

        {/* Brand Positioning Strategy */}
        <div className="col-lg-6 d-flex">
          <img
            src={BrandPositioning}
            alt="Brand Positioning Strategy"
            className="solution-image me-3"
          />
          <div>
            <h5 className="solution-title">Brand Positioning Strategy</h5>
            <p className="solution-description">
              Position your brand to own a unique place in your audience's mind
              by leveraging AI for precision targeting.
            </p>
          </div>
        </div>

        {/* Brand Extension Strategy */}
        <div className="col-lg-6 d-flex">
          <img
            src={BrandExtension}
            alt="Brand Extension Strategy"
            className="solution-image me-3"
          />
          <div>
            <h5 className="solution-title">Brand Extension Strategy</h5>
            <p className="solution-description">
              The focus here is to extend one's brand into new markets while
              retaining core identity and value.
            </p>
          </div>
        </div>

        {/* B2C Branding Strategy */}
        <div className="col-lg-6 d-flex">
          <img
            src={B2CBranding}
            alt="B2C Branding Strategy"
            className="solution-image me-3"
          />
          <div>
            <h5 className="solution-title">B2C Branding Strategy</h5>
            <p className="solution-description">
              Develop a story that engages consumers on an emotional plane so as
              to earn the loyalty of customers.
            </p>
          </div>
        </div>

        {/* Integrated Brand Strategy */}
        <div className="col-lg-6 d-flex">
          <img
            src={IntegratedBrand}
            alt="Integrated Brand Strategy"
            className="solution-image me-3"
          />
          <div>
            <h5 className="solution-title">Integrated Brand Strategy</h5>
            <p className="solution-description">
              Bring it all together, seamlessly. Unified branding across
              channels for maximum impact and powerful identity.
            </p>
          </div>
        </div>

        {/* Digital Media Branding Strategy */}
        <div className="col-lg-6 d-flex">
          <img
            src={DigitalMedia}
            alt="Digital Media Branding Strategy"
            className="solution-image me-3"
          />
          <div>
            <h5 className="solution-title">Digital Media Branding Strategy</h5>
            <p className="solution-description">
              Taking advantage of AI-enhanced analytics for impactful digital
              presence building online.
            </p>
          </div>
        </div>

        {/* Personal Branding Strategy */}
        <div className="col-lg-6 d-flex">
          <img
            src={PersonalBranding}
            alt="Personal Branding Strategy"
            className="solution-image me-3"
          />
          <div>
            <h5 className="solution-title">Personal Branding Strategy</h5>
            <p className="solution-description">
              Build a strong personal brand that becomes the beacon of
              credibility and influence within your selected niche.
            </p>
          </div>
        </div>
      </div>
    </section>

    {/* CTA starts here */}
    <section className="brand-section d-flex align-items-center justify-content-center">
      <div className="text-center">
        <h2 className="section-title text-light">Your Brand, Elevated</h2>
        <p className="section-description">
          At <strong>Statix.pro</strong>, your brand's success is not a goal; it's our promise. 
          Let's take care of all the complexities in modern branding while you simply unlock your 
          true potential by learning about AI.
        </p>
        <button className="btn section-button">
          Let's Create Something Extraordinary
        </button>
        <p className="section-footer">
          Reimagine your brand today. Connect with us to explore how Statix.pro can 
          drive your identity into the limelight.
        </p>
      </div>
    </section>
    {/* CTA ends here */}
    <Footer/>
    <Whatsappbutton/>
<Backtotopbtn/>
{/* chatsection */}
<Chatsection/>
    </div>
  )
}

export default BrandStratergy