// WhatsAppButton.jsx
import React from 'react';
import './whatsappbutton.css';

function Whatsappbutton() {
  const openWhatsApp = () => {
    const phoneNumber = "+919361091908"; // Replace with your WhatsApp number
    const message = "Hello, I'm interested in Statix.pro & your services. Willing to collaborate with you.";
    window.open(`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`, '_blank');
  };

  return (
    <button className="whatsapp-button" onClick={openWhatsApp} aria-label="Connect on WhatsApp">
      <i className="fab fa-whatsapp"></i>
    </button>
  );
}

export default Whatsappbutton;
