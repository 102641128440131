import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/scroll_to_top/scrollToTop';
import './App.css';

// Lazy load all page components
// const Home = React.lazy(() => import('./pages/home/home'));
// const Aboutus = React.lazy(() => import('./pages/aboutus/aboutus'));
// const BrandStratergy = React.lazy(() => import('./pages/brand_stratergy/brandStratergy'));
// const BrandingDesign = React.lazy(() => import('./pages/brandingDesigns/brandingDesigns'));
// const DigitalMarketing = React.lazy(() => import('./pages/digital_marketing/digitalMarketing'));
// const TechnologySolutions = React.lazy(() => import('./pages/technologySolutions/technologySolutions'));
// const DataAnalytics = React.lazy(() => import('./pages/dataAnalytics/dataAnalytics'));
// const PrivacyPolicies = React.lazy(() => import('./pages/policies/privacyPolicies'));
// const Pricing = React.lazy(() => import('./pages/policies/pricing'));
// const CancellationTerms = React.lazy(() => import('./pages/policies/cancellationTerms'));
// const Portfolio = React.lazy(() => import('./pages/portfolio/portfolio'));
// const Career = React.lazy(() => import('./pages/career/career'));
// const Contactus = React.lazy(() => import('./pages/contactus/contactus'));

import Home from './pages/home/home';
import Aboutus from './pages/aboutus/aboutus';
import BrandStratergy from './pages/brand_stratergy/brandStratergy';
import BrandingDesign from './pages/brandingDesigns/brandingDesigns';
import DigitalMarketing from './pages/digital_marketing/digitalMarketing';
import TechnologySolutions from './pages/technologySolutions/technologySolutions';
import DataAnalytics from './pages/dataAnalytics/dataAnalytics';
import PrivacyPolicies from './pages/policies/privacyPolicies';
import Pricing from './pages/policies/pricing';
import CancellationTerms from './pages/policies/cancellationTerms';
import Portfolio from './pages/portfolio/portfolio';
import Career from './pages/career/career';
import Contactus from './pages/contactus/contactus';

// Loading spinner component
const LoadingSpinner = () => (
  <div className="loading-spinner">
    <div className="spinner"></div>
  </div>
);

function App() {
  return (
    <Router>
      <div className="App">
        <ScrollToTop />
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<Aboutus />} />
            <Route path="/brand-strategy" element={<BrandStratergy />} />
            <Route path="/branding-designs" element={<BrandingDesign />} />
            <Route path="/digital-marketing" element={<DigitalMarketing />} />
            <Route path="/technology-solutions" element={<TechnologySolutions />} />
            <Route path="/data-analytics" element={<DataAnalytics />} />
            <Route path="/privacy-policy" element={<PrivacyPolicies />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/cancellation-terms" element={<CancellationTerms />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/career" element={<Career />} />
            <Route path="/contact-us" element={<Contactus />} />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
}

export default App;