import React, { useEffect, useRef, useState } from 'react';
import './recent_works.css';

// logos import
import Logo1 from "../assets/clients/Logo1.jpg";
import Logo2 from "../assets/clients/Logo2.jpg";
import Logo3 from "../assets/clients/Logo3.jpg";
import Logo4 from "../assets/clients/Logo4.jpg";
import Logo5 from "../assets/clients/Logo5.jpg";
import Logo6 from "../assets/clients/Logo6.jpg";
import Logo7 from "../assets/clients/Logo7.jpg";
import Logo8 from "../assets/clients/Logo8.jpg";
import Logo9 from "../assets/clients/Logo9.jpg";
import Logo10 from "../assets/clients/Logo10.jpg";
import Logo11 from "../assets/clients/Logo11.jpg";
import Logo12 from "../assets/clients/Logo12.jpg";
import Logo13 from "../assets/clients/Logo13.jpg";
import Logo14 from "../assets/clients/Logo14.jpg";

const RecentWorkSection = () => {
  const scrollContainerRef = useRef(null);
  const [isAutoScrolling, setIsAutoScrolling] = useState(true);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    const maxScrollLeft = scrollContainer.scrollWidth - scrollContainer.clientWidth;
    let scrollAmount = 1;

    const autoScroll = () => {
      if (isAutoScrolling) {
        scrollContainer.scrollLeft += scrollAmount;
        if (scrollContainer.scrollLeft >= maxScrollLeft || scrollContainer.scrollLeft <= 0) {
          scrollAmount *= -1; // Reverse scroll direction when reaching the ends
        }
      }
    };

    const interval = setInterval(autoScroll, 20); // Adjust speed here (lower = faster)

    return () => clearInterval(interval);
  }, [isAutoScrolling]);

  const scrollLeft = () => {
    setIsAutoScrolling(false); // Pause auto-scrolling when using manual navigation
    scrollContainerRef.current.scrollLeft -= 300; // Adjust scroll amount for each button click
  };

  const scrollRight = () => {
    setIsAutoScrolling(false); // Pause auto-scrolling when using manual navigation
    scrollContainerRef.current.scrollLeft += 300;
  };

  return (
    <section className="recent-work-section" style={{paddingTop:"80px", paddingBottom:"80px"}}>
      <h2 className="text-center" style={{paddingBottom:"20px"}}>We Dare to Be Different. A New Era of Brand Elevation, Trusted by</h2>
      <div className="carousel-container container">
        <button className="scroll-button left" onClick={scrollLeft}>‹</button>
        <div className="scroll-container" ref={scrollContainerRef}>
          <div className="work-item">
          <img src={Logo1} alt="KS Rangasamy College of Technology" className="contact-image" />
          </div>
          <div className="work-item">
          <img src={Logo2} alt="Misez" className="contact-image" />
          </div>
          <div className="work-item">
          <img src={Logo3} alt="Enteeon" className="contact-image" />
          </div>
          <div className="work-item">
          <img src={Logo4} alt="Fohnfoods" className="contact-image" />
          </div>
          <div className="work-item">
          <img src={Logo5} alt="Guide2Profit" className="contact-image" />
          </div>
          <div className="work-item">
          <img src={Logo6} alt="Aspergenix" className="contact-image" />
          </div>
          <div className="work-item">
          <img src={Logo7} alt="Vidhya Vikas College of Technology" className="contact-image" />
          </div>
          <div className="work-item">
          <img src={Logo8} alt="A.S.S Matric.Hr.Sec. School" className="contact-image" />
          </div>
          <div className="work-item">
          <img src={Logo9} alt="Dealcraft" className="contact-image" />
          </div>
          <div className="work-item">
          <img src={Logo10} alt="Vedhakoti" className="contact-image" />
          </div>
          <div className="work-item">
          <img src={Logo11} alt="Earthyorigins" className="contact-image" />
          </div>
          <div className="work-item">
          <img src={Logo12} alt="SauravSpeaks" className="contact-image" />
          </div>
          <div className="work-item">
          <img src={Logo13} alt="ExpressionsInked" className="contact-image" />
          </div>
          <div className="work-item">
          <img src={Logo14} alt="Taskgrossing" className="contact-image" />
          </div>
        </div>
        <button className="scroll-button right" onClick={scrollRight}>›</button>
      </div>
    </section>
  );
};

export default RecentWorkSection;
