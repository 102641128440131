import React, { useEffect } from 'react'
import "./career.css"
import { FiArrowRightCircle, FiTool, FiTrendingUp, FiUserCheck, FiClock } from 'react-icons/fi';

import Navbar from '../../components/navbar/navbar';
import AddressBar from '../../components/address_bar/address_bar';
import Footer from '../../components/footer/footer';
import Chatsection from '../../components/chatsection/chatsection';
import Whatsappbutton from '../../components/whatsappbutton/whatsappbutton';
import Backtotopbtn from '../../components/backtotop/backtotop';
import SeoHelmet from '../../components/SEO/SeoHelmet';
import { careerPageSchema } from '../../components/SEO/SeoSchemas';
import ReactGA from 'react-ga4';

import CareerOne from "../../components/assets/career-team.jpg"
import Careertwo from "../../components/assets/career-team-culture.jpg"

function Career() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    
    // Add schema markup to the page
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(careerPageSchema);
    document.head.appendChild(script);

    // Cleanup
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div className="career-page">
      <SeoHelmet 
        title="Careers at StatixPro - Join Our Digital Marketing Team"
        description="Join StatixPro's innovative team of digital marketing experts. We offer exciting opportunities in SEO, content creation, social media, and more."
        keywords="digital marketing jobs, marketing careers, SEO jobs, content writing positions, social media jobs, StatixPro careers"
        canonicalUrl="https://www.statix.pro/career"
        ogImage="https://www.statix.pro/careers-og.jpg"
        ogType="website"
      />
      <AddressBar />
      <section className="servicebs-hero">
        <div className="logo-and-nav">
          <Navbar />
        </div>
        <div className="servicebs-hero-content text-center">
          <h1 className="servicebs_topic">
           <span className="highlight">Recruit, Engage, Excel, Repeat</span>
          </h1>
          <p className="hero-subtitle">
            Join a team that’s redefining success with AI-powered innovation, creativity, and passion. 
            Your journey to a rewarding career starts here.
          </p>
          <a href="https://forms.gle/1ZT8Q6DnyKFb4KPv6" target="_blank" rel="noopener noreferrer">
          <button className="btn section-button mt-3">
            Apply Now <FiArrowRightCircle className="ms-2" />
          </button>
        </a>
        </div>
      </section>


      {/* Section 2 */}
      <section className="shape-future-section py-5">
        <div className="container d-flex flex-column-reverse flex-md-row align-items-center">
          <div className="text-section col-md-6">
            <h2 className="section-title" style={{textAlign:"left"}}>Shape the Future with Us</h2>
            <p className="section-description" style={{textAlign:"left"}}>
              At Statix.pro, we don’t just build campaigns; we build careers. As a leading AI-powered digital marketing company in India, 
              we thrive on innovation, collaboration, and excellence. By joining us, you become part of a dynamic team that drives 
              brands forward through cutting-edge strategies and data-driven solutions.
            </p>
          </div>
          <div className="image-section col-md-6 text-center">
            <img src={CareerOne} alt="Team" className="rounded img-fluid shadow" />
          </div>
        </div>
      </section>

      {/* Section 3 */}
      <section className="who-should-apply py-5 bg-light">
        <div className="container text-center">
          <h2 className="section-title">Who Should Apply to Statix.pro?</h2>
          <div className="row mt-4">
            <div className="col-md-3 col-6">
              <div className="apply-card">
                <FiUserCheck size={36} className="mb-2" />
                <p>Creative Thinkers</p>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="apply-card">
                <FiTrendingUp size={36} className="mb-2" />
                <p>Growth Mindset</p>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="apply-card">
                <FiTool size={36} className="mb-2" />
                <p>Data-Driven</p>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="apply-card">
                <FiClock size={36} className="mb-2" />
                <p>Innovators</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section 5 */}
      <section className="team-section py-5 bg-light">
        <div className="container text-center">
          <h2 className="section-title">How We Built the Statix.pro Team</h2>
          <p className="section-description">
            Our team is our greatest asset. We’ve handpicked individuals who share our passion for creativity, innovation, and excellence.
          </p>

          {/*  */}
          <div className="steps-container mt-4">
            <div className="step">
              <div className="step-number">1</div>
              <p>Submit your resume and share your passion for digital marketing.</p>
            </div>
            <div className="step">
              <div className="step-number">2</div>
              <p>Our team evaluates your experience through a short assignment.</p>
            </div>
            <div className="step">
              <div className="step-number">3</div>
              <p>Engage in meaningful conversations with our team.</p>
            </div>
            <div className="step">
              <div className="step-number">4</div>
              <p>Receive your offer and embark on a rewarding journey.</p>
            </div>
          </div>


          <img src={Careertwo} alt="Team Culture" className="rounded img-fluid shadow mt-4" />
        </div>
      </section>

      {/* Section 6 */}
      <section className="why-join-section py-5">
        <div className="container text-center">
          <h2 className="section-title">Why Join Statix.pro?</h2>
          <div className="row mt-4">
            <div className="col-md-4">
              <FiTool size={48} className="mb-3 text-primary" />
              <p>Hands-on experience with AI tools.</p>
            </div>
            <div className="col-md-4">
              <FiClock size={48} className="mb-3 text-primary" />
              <p>Work-life balance.</p>
            </div>
            <div className="col-md-4">
              <FiTrendingUp size={48} className="mb-3 text-primary" />
              <p>Endless learning opportunities.</p>
            </div>
          </div>
        </div>
      </section>

          {/* section 4 starts here */}
    {/* CTA starts here */}
    <section className="brand-section d-flex align-items-center justify-content-center">
      <div className="text-center">
        <h2 className="section-title text-light">Ready to Take the Leap?</h2>
        <p className="section-description">
        Joining Statix.pro means becoming part of something bigger than yourself.
        </p>
        <a href="https://forms.gle/1ZT8Q6DnyKFb4KPv6" target="_blank" rel="noopener noreferrer">
          <button className="btn section-button mt-3">
            Apply Now <FiArrowRightCircle className="ms-2" />
          </button>
        </a>
      </div>
    </section>
    {/* CTA ends here */}
    {/* section 4 ends here */}

<Footer/>
    <Whatsappbutton/>
<Backtotopbtn/>
{/* chatsection */}
<Chatsection/>
    </div>
  )
}

export default Career