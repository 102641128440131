// Organization Schema
export const organizationSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "StatixPro",
    "url": "https://www.statix.pro",
    "logo": "https://www.statix.pro/logo512.png",
    "description": "AI-powered digital marketing and branding solutions that deliver real results",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "J-Block, KSRCT, K.S.R Educational Institutions, near KSRCT IT Park, KSR Kalvi Nagar",
      "addressLocality": "Tiruchengode",
      "addressRegion": "Tamil Nadu",
      "postalCode": "637215",
      "addressCountry": "IN"
    },
    "sameAs": [
      "https://www.linkedin.com/company/statixpro",
      "https://twitter.com/statixpro",
      "https://www.instagram.com/statixpro"
    ],
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+91-8248986847",
      "contactType": "customer service",
      "availableLanguage": ["English"]
    },
    "foundingDate": "2023",
    "founders": [{
      "@type": "Person",
      "name": "StatixPro Team"
    }],
    "award": [
        {
          "@type": "Award",
          "name": "Best Digital Marketing Agency - Tamil Nadu 2023"
        }
      ],
      "slogan": "Your Brand, Our Strategy",
      "knowsAbout": [
        "Digital Marketing",
        "SEO",
        "Social Media Marketing",
        "Content Creation",
        "Web Development",
        "Branding",
        "Data Analytics",
        "AI Technology"
      ]
  };
  
  // Website Schema
  export const websiteSchema = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "StatixPro",
    "url": "https://www.statix.pro",
    "description": "AI-powered digital marketing and branding solutions that deliver real results",
    "potentialAction": {
      "@type": "SearchAction",
      "target": {
        "@type": "EntryPoint",
        "urlTemplate": "https://www.statix.pro/search?q={search_term_string}"
      },
      "query-input": "required name=search_term_string"
    },
    "publisher": {
      "@type": "Organization",
      "name": "StatixPro",
      "logo": {
        "@type": "ImageObject",
        "url": "https://www.statix.pro/logo512.png"
      }
    }
  };
  
  // Professional Service Schema
  export const professionalServiceSchema = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    "name": "StatixPro Digital Marketing Services",
    "url": "https://www.statix.pro",
    "image": "https://www.statix.pro/logo512.png",
    "priceRange": "₹₹₹",
    "hasOfferCatalog": {
      "@type": "OfferCatalog",
      "name": "Digital Marketing Services",
      "itemListElement": [
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Digital Marketing",
            "description": "Comprehensive digital marketing solutions including SEO, SEM, and Social Media Marketing"
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "AI Website Development",
            "description": "Custom AI-powered website development solutions"
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Branding Solutions",
            "description": "Professional branding and identity design services"
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Data Analytics",
            "description": "Advanced data analytics and business intelligence services"
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Content Creation",
            "description": "Professional content writing and copywriting services"
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Lead Generation",
            "description": "Strategic lead generation and conversion optimization"
          }
        }
      ]
    },
    "areaServed": {
      "@type": "Country",
      "name": "India"
    },
    "serviceType": [
      "Digital Marketing",
      "AI Web Development",
      "Branding",
      "Data Analytics",
      "Content Creation",
      "Lead Generation"
    ],
    "review": {
      "@type": "Review",
      "reviewRating": {
        "@type": "Rating",
        "ratingValue": "5",
        "bestRating": "5"
      },
      "author": {
        "@type": "Person",
        "name": "Client Review"
      },
      "reviewBody": "Excellent digital marketing services with measurable results!"
    }
};
  // About Page Schema
  export const aboutPageSchema = {  
  "@context": "https://schema.org",
  "@type": "AboutPage",
  "name": "About StatixPro",
  "description": "Learn about StatixPro's mission, values, and team",
  "breadcrumb": {
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://www.statix.pro"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "About Us",
        "item": "https://www.statix.pro/aboutus"
      }
    ]
  }
};

// Services Schemas
export const servicesSchemas = {
  brandStrategy: {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": "Brand Strategy Services",
    "description": "Professional brand strategy and development services",
    "provider": {
      "@type": "Organization",
      "name": "StatixPro"
    },
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://www.statix.pro"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Services",
          "item": "https://www.statix.pro/services"
        },
        {
          "@type": "ListItem",
          "position": 3,
          "name": "Brand Strategy",
          "item": "https://www.statix.pro/brand-strategy"
        }
      ]
    }
  },
  digitalMarketing: {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": "Digital Marketing Services",
    "description": "Comprehensive digital marketing solutions",
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://www.statix.pro"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Services",
          "item": "https://www.statix.pro/services"
        },
        {
          "@type": "ListItem",
          "position": 3,
          "name": "Digital Marketing",
          "item": "https://www.statix.pro/digital-marketing"
        }
      ]
    }
  },
  dataAnalytics: {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": "Data Analytics Services",
    "description": "Advanced data analytics and insights",
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://www.statix.pro"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Services",
          "item": "https://www.statix.pro/services"
        },
        {
          "@type": "ListItem",
          "position": 3,
          "name": "Data Analytics",
          "item": "https://www.statix.pro/data-analytics"
        }
      ]
    }
  }
};

// Contact Page Schema
export const contactPageSchema = {
  "@context": "https://schema.org",
  "@type": "ContactPage",
  "name": "Contact StatixPro",
  "description": "Get in touch with StatixPro for your digital marketing needs",
  "breadcrumb": {
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://www.statix.pro"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Contact",
        "item": "https://www.statix.pro/contactus"
      }
    ]
  }
};

    // Portfolio Page Schema
export const portfolioPageSchema = {
  "@context": "https://schema.org",
  "@type": "CollectionPage",
  "name": "StatixPro Portfolio",
  "description": "View our successful projects and case studies",
  "breadcrumb": {
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://www.statix.pro"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Portfolio",
        "item": "https://www.statix.pro/portfolio"
      }
    ]
  }
};

// Career Page Schema
export const careerPageSchema = {
  "@context": "https://schema.org",
  "@type": "JobPosting",
  "name": "Careers at StatixPro",
  "description": "Join our team of digital marketing experts",
  "breadcrumb": {
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://www.statix.pro"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Careers",
        "item": "https://www.statix.pro/career"
      }
    ]
  }
};

// Policies Schemas
export const policiesSchemas = {
    privacyPolicy: {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": "Privacy Policy - StatixPro",
      "description": "StatixPro's privacy policy and data protection information",
      "mainEntity": {
        "@type": "WebPage",
        "name": "Privacy Policy",
        "text": "Privacy policy and data protection guidelines for StatixPro's digital marketing services"
      },
      "breadcrumb": {
        "@type": "BreadcrumbList",
        "itemListElement": [
          {
            "@type": "ListItem",
            "position": 1,
            "name": "Home",
            "item": "https://www.statix.pro"
          },
          {
            "@type": "ListItem",
            "position": 2,
            "name": "Privacy Policy",
            "item": "https://www.statix.pro/privacy-policy"
          }
        ]
      }
    },
    cancellationTerms: {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": "Cancellation Terms - StatixPro",
      "description": "Cancellation and refund policy for StatixPro's services",
      "mainEntity": {
        "@type": "WebPage",
        "name": "Cancellation Terms",
        "text": "Terms and conditions for service cancellation and refunds"
      },
      "breadcrumb": {
        "@type": "BreadcrumbList",
        "itemListElement": [
          {
            "@type": "ListItem",
            "position": 1,
            "name": "Home",
            "item": "https://www.statix.pro"
          },
          {
            "@type": "ListItem",
            "position": 2,
            "name": "Cancellation Terms",
            "item": "https://www.statix.pro/cancellation-terms"
          }
        ]
      }
    },
    pricing: {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": "Pricing & Plans - StatixPro",
      "description": "Digital marketing service packages and pricing plans",
      "mainEntity": {
        "@type": "PriceSpecification",
        "name": "Digital Marketing Services Pricing",
        "description": "Flexible pricing plans for digital marketing services"
      },
      "breadcrumb": {
        "@type": "BreadcrumbList",
        "itemListElement": [
          {
            "@type": "ListItem",
            "position": 1,
            "name": "Home",
            "item": "https://www.statix.pro"
          },
          {
            "@type": "ListItem",
            "position": 2,
            "name": "Pricing",
            "item": "https://www.statix.pro/pricing"
          }
        ]
      }
    }
  };
  
// FAQ Schema
export const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "What digital marketing services does StatixPro offer?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "StatixPro offers comprehensive digital marketing services including SEO, social media marketing, content creation, AI-powered web development, branding solutions, data analytics, lead generation, and custom software development."
        }
      },
      {
        "@type": "Question",
        "name": "How does StatixPro use AI in digital marketing?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "We leverage AI technology for data analysis, content optimization, personalized marketing strategies, automated campaign management, and predictive analytics to deliver better results for our clients."
        }
      },
      {
        "@type": "Question",
        "name": "What makes StatixPro different from other digital marketing agencies?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "StatixPro stands out through our AI-powered approach, data-driven strategies, comprehensive service offerings, and proven track record of delivering measurable results for our clients."
        }
      },
      {
        "@type": "Question",
        "name": "How can I get started with StatixPro's services?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "You can get started by contacting us through our website, scheduling a free consultation, or filling out our service inquiry form. Our team will assess your needs and create a customized solution."
      }
    },
    {
        "@type": "Question",
        "name": "What is the importance of data analytics in digital marketing?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Data analytics is crucial in digital marketing as it helps understand customer behavior, optimize campaigns, and make data-driven decisions. It enables businesses to measure performance, identify trends, and adjust strategies for better results."
        }
      },
      {
        "@type": "Question",
        "name": "How long does it take to see results from digital marketing efforts?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "The timeline for results varies depending on factors like your goals, market, and chosen strategies. While some improvements can be seen within weeks, sustainable results typically develop over 3-6 months as we optimize and refine our approach based on data insights."
        }
      }
    ]
  };
        