import React, { useEffect } from 'react'
import "./contactus.css"
import Navbar from '../../components/navbar/navbar';
import AddressBar from '../../components/address_bar/address_bar';
import Footer from '../../components/footer/footer';
import Chatsection from '../../components/chatsection/chatsection';
import Whatsappbutton from '../../components/whatsappbutton/whatsappbutton';
import Backtotopbtn from '../../components/backtotop/backtotop';
import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt} from 'react-icons/fa';
import SeoHelmet from '../../components/SEO/SeoHelmet';
import { contactPageSchema } from '../../components/SEO/SeoSchemas';
import ReactGA from 'react-ga4';

function Contactus() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    
    // Add schema markup to the page
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(contactPageSchema);
    document.head.appendChild(script);

    // Cleanup
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div>
      <SeoHelmet 
        title="Contact StatixPro - Get in Touch for Digital Marketing Solutions"
        description="Connect with StatixPro for all your digital marketing needs. Our team is ready to help transform your business with AI-powered marketing solutions."
        keywords="contact StatixPro, digital marketing contact, marketing consultation, get in touch, business inquiry, marketing services contact"
        canonicalUrl="https://www.statix.pro/contactus"
        ogImage="https://www.statix.pro/contact-og.jpg"
        ogType="website"
      />
        <AddressBar />
      <section className="servicebs-hero">
        <div className="logo-and-nav">
          <Navbar />
        </div>
        <div className="servicebs-hero-content text-center">
          <h1 className="servicebs_topic">
          We’d Love to Hear from You!
          </h1>
        </div>
      </section>

       {/* Section 2 */}
       <div className="container my-5">
      <div className="row">
        {/* Card 1 */}
        <div className="col-lg-4 col-md-6 mb-4">
          <div className="solution-card text-center">
            <div className="icon-circle mx-auto mb-3">
              <FaMapMarkerAlt />
            </div>
            <h5 className="card-title">Location</h5>
            <p className="card-description">
            J-Block, KSRCT, K.S.R Educational Institutions, near KSRCT IT Park, KSR Kalvi Nagar, Tiruchengode, Tamil Nadu -637215 </p>
              <br/>
              <p className="card-description"> <span style={{background:"#0056B3", padding:"10px", color:"#fff", borderRadius:"20px"}}>Incubatee of KSRCT</span> </p>
            
          </div>
        </div>

        {/* Card 2 */}
        <div className="col-lg-4 col-md-6 mb-4">
          <div className="solution-card text-center">
            <div className="icon-circle mx-auto mb-3">
              <FaEnvelope />
            </div>
            <h5 className="card-title">Email</h5>
            <p className="card-description">
              Do Contact us through,
            </p>
            <p className="card-description"> <span style={{background:"#0056B3", padding:"10px", color:"#fff", borderRadius:"20px"}}>support@statix.pro</span> </p> <br/>
            <p className="card-description"> <span style={{background:"#0056B3", padding:"10px", color:"#fff", borderRadius:"20px"}}>contact@statix.pro</span> </p>
          </div>
        </div>

        {/* Card 3 */}
        <div className="col-lg-4 col-md-6 mb-4">
          <div className="solution-card text-center">
            <div className="icon-circle mx-auto mb-3">
              <FaPhoneAlt />
            </div>
            <h5 className="card-title">Phone Number</h5>
            <p className="card-description"> Kindly call our Team if you have any requirements or immediate support </p>
            <p className="card-description"> <span style={{background:"#0056B3", padding:"10px", color:"#fff", borderRadius:"10px"}}>+91 96778 05622</span> </p> <br/>
            <p className="card-description"> <span style={{background:"#0056B3", padding:"10px", color:"#fff", borderRadius:"20px"}}>+91 82489 86847</span> </p>
          </div>
        </div>

        <div>
        </div>
      </div>
    </div>

      <Footer />
      <Whatsappbutton />
      <Backtotopbtn />
      <Chatsection />
    </div>
  )
}

export default Contactus