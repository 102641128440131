import React from 'react';
import { FaPhoneAlt, FaEnvelope, FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { FiClock } from 'react-icons/fi';
import "./address_bar.css";

function AddressBar() {
  return (
    <div className='container address-bar'>
      <div className="contact-info">
        {/* Left side: Contact Items */}
        <div className="contact-item">
          <FaPhoneAlt className="icon" />
          <a href="tel:8248986847">8248986847</a>
        </div>
        <div className="contact-item">
          <FiClock className="icon clock-icon" />
          <span>Monday-Friday 9am - 7pm</span>
        </div>
        <div className="contact-item">
          <FaEnvelope className="icon" />
          <a href="mailto:contact@statix.pro">contact@statix.pro</a>
        </div>
      </div>

      {/* Right side: Social Media Icons */}
      <div className="social-icons">
        <a href="https://www.facebook.com/people/Statix_pro/100092174995115/" target="_blank" rel="noopener noreferrer" aria-label="StatixPro Facebook Page"><FaFacebookF className="social-icon" alt="" /></a>
        <a href="https://www.instagram.com/statix_pro" target="_blank" rel="noopener noreferrer" aria-label="StatixPro Instagram Page"><FaInstagram className="social-icon" alt="" /></a>
        <a href="https://www.linkedin.com/company/statix-pro/" target="_blank" rel="noopener noreferrer" aria-label="StatixPro LinkedIn Page"><FaLinkedinIn className="social-icon" alt="" /></a>
      </div>
    </div>
  );
}

export default AddressBar;
