// BackToTopButton.jsx
import React, { useEffect, useState } from 'react';
import './backtotop.css';

function Backtotopbtn() {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled down
  useEffect(() => {
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    isVisible && (
      <button className="back-to-top-button" onClick={scrollToTop} aria-label="Back to Top">
        <i className="fas fa-arrow-up"></i>
      </button>
    )
  );
}

export default Backtotopbtn;
